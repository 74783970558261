<template>
  <div class="loading">
    <div class="loading_mask">
      <div class="multi-spinner-container">
        <div class="multi-spinner">
          <div class="multi-spinner">
            <div class="multi-spinner">
              <div class="multi-spinner">
                <div class="multi-spinner">
                  <div class="multi-spinner">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    name: "LoadingInplayComp"
  }
</script>

<style scoped>
  @import url("../assets/css/loading.css");
</style>