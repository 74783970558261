import { render, staticRenderFns } from "./Way2YesNo.vue?vue&type=template&id=3ae5c661&scoped=true&"
import script from "./Way2YesNo.vue?vue&type=script&lang=js&"
export * from "./Way2YesNo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ae5c661",
  null
  
)

export default component.exports