<template>

    <!--베팅카트-->
    <div class="bet_cart">
        <div class="bet_cart_timer">
            <clock></clock>
        </div>
        <div class="cart">
            <div class="cart_selected_items" v-for="(item,index) in $store.state.inplayCartItems" :key="item.id">
                <div class="item_inplay" :key="item.id">
                    <div class="tn">
                        <span class="" v-if="$store.state.country === i18nConst.KR">{{item.homeName}}</span>
                        <span class="" v-if="$store.state.country === i18nConst.EN">{{item.homeNameEn}}</span>
                            <span class="" v-if="$store.state.country === i18nConst.JP">{{item.homeNameJp}}</span>
                        <span class="" v-if="$store.state.country === i18nConst.FRA">{{item.homeNameFr}}</span>
                        <span class="" v-if="$store.state.country === i18nConst.SPA">{{item.homeNameSp}}</span>

                        <span style="padding: 0 3px; color: blue">vs</span>

                        <span class="" v-if="$store.state.country === i18nConst.KR">{{item.awayName}}</span>
                        <span class="" v-if="$store.state.country === i18nConst.EN">{{item.awayNameEn}}</span>
                        <span class="" v-if="$store.state.country === i18nConst.JP">{{item.awayNameJp}}</span>
                        <span class="" v-if="$store.state.country === i18nConst.FRA">{{item.awayNameFr}}</span>
                        <span class="" v-if="$store.state.country === i18nConst.SPA">{{item.awayNameSp}}</span>

                    </div>
                    <div class="type">
                        <i v-if="item.typeText === '축구'">{{i18nConst.GAME_TYPE['SOCCER'][$store.state.country]}}</i>
                        <i v-if="item.typeText === '야구'">{{i18nConst.GAME_TYPE['BASEBALL'][$store.state.country]}}</i>
                        <i v-if="item.typeText === '농구'">{{i18nConst.GAME_TYPE['BASKETBALL'][$store.state.country]}}</i>
                        <i v-if="item.typeText === '배구'">{{i18nConst.GAME_TYPE['VOLLEYBALL'][$store.state.country]}}</i>
                        <i v-if="item.typeText === '하키'">{{i18nConst.GAME_TYPE['HOCKEY'][$store.state.country]}}</i>
                        ,
                        <i v-if="item.sectionText === '풀타임'">{{i18nConst.BET['FULL_TIME'][$store.state.country]}}</i>
                        <i v-if="item.sectionText === '전반전'">{{i18nConst.COMMON['HALF1'][$store.state.country]}}</i>
                        <i v-if="item.sectionText === '후반전'">{{i18nConst.COMMON['HALF2'][$store.state.country]}}</i>
                        <i v-if="item.sectionText === '1-3이닝'">{{i18nConst.COMMON['1_3N'][$store.state.country]}}</i>
                        <i v-if="item.sectionText === '1-5이닝'">{{i18nConst.COMMON['1_5N'][$store.state.country]}}</i>
                        <i v-if="item.sectionText === '1-7이닝'">{{i18nConst.COMMON['1_7N'][$store.state.country]}}</i>
                        <i v-if="item.sectionText === '1이닝'">{{i18nConst.COMMON['1_N'][$store.state.country]}}</i>
                        <i v-if="item.sectionText === '2이닝'">{{i18nConst.COMMON['2_N'][$store.state.country]}}</i>
                        <i v-if="item.sectionText === '3이닝'">{{i18nConst.COMMON['3_N'][$store.state.country]}}</i>
                        <i v-if="item.sectionText === '4이닝'">{{i18nConst.COMMON['4_N'][$store.state.country]}}</i>
                        <i v-if="item.sectionText === '5이닝'">{{i18nConst.COMMON['5_N'][$store.state.country]}}</i>
                        <i v-if="item.sectionText === '6이닝'">{{i18nConst.COMMON['6_N'][$store.state.country]}}</i>
                        <i v-if="item.sectionText === '7이닝'">{{i18nConst.COMMON['7_N'][$store.state.country]}}</i>
                        <i v-if="item.sectionText === '8이닝'">{{i18nConst.COMMON['8_N'][$store.state.country]}}</i>
                        <i v-if="item.sectionText === '1Q'">{{i18nConst.COMMON['1_Q'][$store.state.country]}}</i>
                        <i v-if="item.sectionText === '2Q'">{{i18nConst.COMMON['2_Q'][$store.state.country]}}</i>
                        <i v-if="item.sectionText === '3Q'">{{i18nConst.COMMON['3_Q'][$store.state.country]}}</i>
                        <i v-if="item.sectionText === '4Q'">{{i18nConst.COMMON['4_Q'][$store.state.country]}}</i>
                        <i v-if="item.sectionText === '1세트'">{{i18nConst.COMMON['1_SET'][$store.state.country]}}</i>
                        <i v-if="item.sectionText === '1세트'">{{i18nConst.COMMON['1_SET'][$store.state.country]}}</i>
                        <i v-if="item.sectionText === '1세트'">{{i18nConst.COMMON['1_SET'][$store.state.country]}}</i>
                        <i v-if="item.sectionText === '1Period'">{{i18nConst.COMMON['1_P'][$store.state.country]}}</i>
                        <i v-if="item.sectionText === '2Period'">{{i18nConst.COMMON['1_P'][$store.state.country]}}</i>
                        <i v-if="item.sectionText === '3Period'">{{i18nConst.COMMON['1_P'][$store.state.country]}}</i>
                        ,
                        <i v-if="item.kindText === '승무패'">{{i18nConst.BET['WIN_TIE_LOSE'][$store.state.country]}}</i>
                        <i v-if="item.kindText === '핸디캡 2WAY'">{{i18nConst.COMMON['HANDICAP_2WAY'][$store.state.country]}}</i>
                        <i v-if="item.kindText === '핸디캡 3WAY'">{{i18nConst.COMMON['HANDICAP_3WAY'][$store.state.country]}}</i>
                        <i v-if="item.kindText === '오버언더'">{{i18nConst.BET['OVER_UNDER'][$store.state.country]}}</i>
                        <i v-if="item.kindText === '양팀 모두 득점'">{{i18nConst.BET['BOTH_TEAM_TO_SCORE'][$store.state.country]}}</i>
                        <i v-if="item.kindText === '스코어 홀짝'">{{i18nConst.BET['SCORE_ODD_EVEN'][$store.state.country]}}</i>
                        <i v-if="item.kindText === '최종스코어'">{{i18nConst.COMMON['FINAL_SCORE'][$store.state.country]}}</i>
                        <i v-if="item.kindText === '안타 총개수'">{{i18nConst.COMMON['TOTAL_HITS'][$store.state.country]}}</i>
                        <i v-if="item.kindText === '승패'">{{i18nConst.BET['WIN_TIE_LOSE'][$store.state.country]}}</i>
                        <i v-if="item.kindText === 'Alternative 오버언더'">{{i18nConst.COMMON['ALTERNATIVE_OVERUNDER'][$store.state.country]}}</i>
                        <i v-if="item.kindText === '안타 승무패'">{{i18nConst.COMMON['HITS_WIN_LOSE'][$store.state.country]}}</i>
                        <i v-if="item.kindText === '안타 오버언더'">{{i18nConst.COMMON['HITS_OVERUNDER'][$store.state.country]}}</i>
                        <i v-if="item.kindText === 'BOTH_TEAMS_SCORE'">{{i18nConst.COMMON['BOTH_TEAMS_SCORE'][$store.state.country]}}</i>
                        <i v-if="item.kindText === '스코어'">{{i18nConst.COMMON['SCORE'][$store.state.country]}}</i>
                        <i v-if="item.kindText === '홀짝'">{{i18nConst.COMMON['ODD_EVEN'][$store.state.country]}}</i>
                        <i v-if="item.kindText === 'Alt Puck 핸디캡'">{{i18nConst.COMMON['ALT_PUCK_HANDICAP'][$store.state.country]}}</i>
                        <i v-if="item.kindText === 'Alt Puck 오버언더'">{{i18nConst.COMMON['ALT_PUCK_OVERUNDER'][$store.state.country]}}</i>

                    </div>
                    <div class="sel">
                                <span class="st">
                                    {{item.selectText}}
                                </span>
                        <span class="bd">
                                    {{item.selectOdd}}
                                </span>
                        <span class="del" @click="del(item)">
                                    <i class="fa fa-close"></i>
                                </span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="$store.state.inplayCartItems.length === 0" class="cart_selected_items">
            <div class="item">{{i18nConst.BET['SELECT_BET_GAME'][$store.state.country]}}</div>
        </div>
        <div class="info">
            <div class="item">
                <div>
                    <span class="t1">{{i18nConst.BET['MAX_BET_CASH'][$store.state.country]}}</span><span class="t2">{{this.rankConfig.inplayBetMaxLimitCash|comma}} $</span>
                </div>
                <div>
                    <span class="t1">{{i18nConst.BET['MAX_WIN_CASH'][$store.state.country]}}</span><span class="t2">{{this.rankConfig.inplayWinMaxLimitCash|comma}} $</span>
                </div>
                <div>
                    <span class="t1">{{i18nConst.BET['GAME_MONEY'][$store.state.country]}}</span><span class="t2">{{$store.state.userInfo.cash|comma}} $</span>
                </div>
                <div v-if="bonusOdds > 0" class="bg-color-gray it">
                    <span class="t1 text-white">{{i18nConst.BET['BONUS_RATE'][$store.state.country]}}</span><span class="t2 text-white">{{bonusOdds}}</span>
                </div>
                <div v-if="bonusCash > 0" class="bg-color-gray it">
                    <span class="t1 text-white">{{i18nConst.BET['BONUS_AMOUNT'][$store.state.country]}}</span><span class="t2 text-white">{{bonusCash|comma}}</span>
                </div>
                <div>
                    <span class="t1 text-gray">{{i18nConst.BET['RATE'][$store.state.country]}}</span><span class="t2 text-blue">{{totalOdd}}</span>
                </div>
                <div>
                    <span class="t1 text-gray">{{i18nConst.BET['EXPECTED_WIN_CASH'][$store.state.country]}}</span><span class="t2 text-blue">{{totalWinCash|comma}}</span>
                </div>
                <div>
                    <span class="t1 text-gray">{{i18nConst.BET['BET_CASH'][$store.state.country]}}</span>
                    <span class="t2">
                                <input type="text" v-model="betCash" style="width: 100px;"/>
                            </span>
                </div>
            </div>
            <div class="money_buttons">
                <button class="" @click="cashAdd(5)">5$</button>
                <button class="" @click="cashAdd(10)">10$</button>
                <button class="" @click="cashAdd(50)">50$</button>
                <button class="" @click="cashAdd(100)">100$</button>
                <button class="" @click="cashAdd(500)">500$</button>
                <button class="" @click="cashAdd(1000)">1000$</button>
                <button class=" " @click="maxCash()">MAX</button>
                <button class=" " @click="resetCash()">Cash Reset</button>
                <button class=" " @click="initBetCart()">{{i18nConst.BET['INIT'][$store.state.country]}}</button>
            </div>
            <div class="btn_bet">
                <button @click="betNow()">{{i18nConst.BET['DO_BET'][$store.state.country]}}</button>
            </div>
        </div>
    </div>


</template>

<script>
    import {
        RECEIVE_HIDE_LOADING, RECEIVE_HIDE_LOADING_INPLAY,
        RECEIVE_INPLAY_CART_ITEM_DEL,
        RECEIVE_INPLAY_CART_ITEM_DEL_ALL,
        RECEIVE_SHOW_LOADING,
        RECEIVE_SHOW_LOADING_INPLAY,
    } from "../../store/mutation-types";
    import sportsConst from "../../common/sportsConst";
    import {mapGetters} from 'vuex'
    import {dobet, doinplaybet} from "../../network/sportsBetRequest";
    import Clock from "../Clock";
    import {checkLoginMinxin} from "../../common/mixin";
    import i18nConst from "../../common/i18nConst";

    export default {
        name: "InplayBetCartComp",
        mixins:[checkLoginMinxin],
        components: {Clock},
        data() {
            return {
                sportsConst,
                i18nConst:i18nConst,
                odds: 0,//선택경기 총배당
                winCash: 0,//총당첨금액
                betCash: 0,//베팅금액
                betInfo: {
                    selectedVals: [],
                    betCash: 0
                },
                bonusCash: 0,//보너스 금액
                bonusOdds: 0,//보너스 배당,
                mobileBetCartClose: false,
                locked: false
            }
        },
        methods: {
            /*mobile 페이지 배팅카드 보여줄시 닫기*/
            closeMobileCart() {
                //RightBarSportsComp.vue에서 모니터
                this.$bus.$emit('rightBarHandle', false);
            },
            /*배팅카트 아이템 삭제*/
            del(item) {
                this.$bus.$emit('deleteInplayCartItem', item);
            },
            /*베팅카드 머니 버튼클릭*/
            cashAdd(amount) {
                this.betCash = this.$root.$options.filters.comma(this.getBetCash + parseInt(amount))
            },
            resetCash() {
                this.betCash = 0;
            },
            /*다폴더 보너스*/
            initBonus() {
                /*보너스*/
                this.bonusCash = 0;
                this.bonusOdds = 0;
                let folderCount = this.cartItems.length;
                let bonusIntotal = 0;
                this.cartItems.map(function (item) {
                    if (item.selectOdd < sportsConst.BONUS_BEDANG_EXCEPT_FLAG) {
                        folderCount--;
                    }
                })
                switch (folderCount) {
                    case 0:
                    case 1:
                        this.bonusOdds = 0;
                        break
                    case 2:
                        if (this.bonusConfig.f2 > 1) {
                            this.bonusOdds = this.bonusConfig.f2;
                        }
                        break;
                    case 3:
                        if (this.bonusConfig.f3 > 1) {
                            this.bonusOdds = this.bonusConfig.f3;
                        }
                        break;
                    case 4:
                        if (this.bonusConfig.f4 > 1) {
                            this.bonusOdds = this.bonusConfig.f4;
                        }
                        break;
                    case 5:
                        if (this.bonusConfig.f5 > 1) {
                            this.bonusOdds = this.bonusConfig.f5;
                        }
                        break;
                    case 6:
                        if (this.bonusConfig.f6 > 1) {
                            this.bonusOdds = this.bonusConfig.f6;
                        }
                        break;
                    case 7:
                        if (this.bonusConfig.f7 > 1) {
                            this.bonusOdds = this.bonusConfig.f7;
                        }
                        break;
                    case 8:
                        if (this.bonusConfig.f8 > 1) {
                            this.bonusOdds = this.bonusConfig.f8;
                        }
                        break;
                    case 9:
                        if (this.bonusConfig.f9 > 1) {
                            this.bonusOdds = this.bonusConfig.f9;
                        }
                        break;
                    default:
                        if (this.bonusConfig.f10 > 1) {
                            this.bonusOdds = this.bonusConfig.f10;
                        }
                        break;
                }
                bonusIntotal = Math.round(this.getBetCash * (this.odds * this.bonusOdds).toFixed(2));
                this.bonusCash = bonusIntotal - this.totalWinCash;
            },
            /*베팅카드 초기화 ,아이템 전체 삭제*/
            initBetCart() {
                this.$bus.$emit('deleteInplayAllCartItem');
            },
            /*상한가 베팅*/
            maxCash() {
                // if (this.odds <= 1 || this.cartItems.length === 0) return false;
                // this.betCash = this.$root.$options.filters.comma(Math.floor((this.rankConfig.inplayWinMaxLimitCash) / this.odds))
                this.betCash = this.$root.$options.filters.comma(this.$store.state.userInfo.cash)
            },
            /*베팅하기*/
            betNow() {
                if(!this.checkLogin()){
                    return false
                }
                //선택경기개수 체크
                if (this.cartItems.length === 0) {
                    this.$swal({
                        title: i18nConst.COMMON['SELECT_BET_GAME'][this.$store.state.country],
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }

                //단폴더 체크
                if (this.cartItems.length === 1) {
                    if (this.getBetCash > this.rankConfig.inplayBetOneMaxLimitCash) {

                        this.$swal({
                            title: this.$root.$options.filters.replace(i18nConst.BET['DO_BET_MSG_2'][this.$store.state.country],'##',this.$root.$options.filters.comma(this.rankConfig.inplayBetOneMaxLimitCash)),
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        return false;
                    }
                }
                //두폴더 체크
                if (this.cartItems.length === 2) {
                    if (this.getBetCash > this.rankConfig.inplayBetTwoMaxLimitCash) {
                        this.$swal({
                            title: this.$root.$options.filters.replace(i18nConst.BET['DO_BET_MSG_3'][this.$store.state.country],'##',this.$root.$options.filters.comma(this.rankConfig.inplayBetTwoMaxLimitCash)),
                            // title: '두폴더 최대 베팅금액 ' + this.$root.$options.filters.comma(this.rankConfig.inplayBetTwoMaxLimitCash) + ' $을 초과하였습니다',
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        return false;
                    }
                }

                //최고베당체크
                if (this.odds > this.rankConfig.inplayOddsMaxLimit) {
                    this.$swal({
                        // title: '최대 배당율 ' + this.$root.$options.filters.comma(this.rankConfig.inplayOddsMaxLimit) + ' 배를 초과하였습니다',
                        title: this.$root.$options.filters.replace(i18nConst.BET['DO_BET_MSG_4'][this.$store.state.country],'##',this.$root.$options.filters.comma(this.rankConfig.inplayOddsMaxLimit)),
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                //최소베팅금액
                if (this.getBetCash < this.rankConfig.inplayBetMinLimitCash) {
                    this.$swal({
                        // title: '최소 베팅금액은 ' + this.$root.$options.filters.comma(this.rankConfig.inplayBetMinLimitCash) + ' $입니다',
                        title: this.$root.$options.filters.replace(i18nConst.BET['DO_BET_MSG_5'][this.$store.state.country],'##',this.$root.$options.filters.comma(this.rankConfig.inplayBetMinLimitCash)),

                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                //최대베팅금액
                if (this.getBetCash > this.rankConfig.inplayBetMaxLimitCash) {
                    this.$swal({
                        // title: '최대 베팅금액 ' + this.$root.$options.filters.comma(this.rankConfig.inplayBetMaxLimitCash) + ' $을 초과하였습니다',
                        title: this.$root.$options.filters.replace(i18nConst.BET['DO_BET_MSG_6'][this.$store.state.country],'##',this.$root.$options.filters.comma(this.rankConfig.inplayBetMaxLimitCash)),
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                //최대당첨금액
                if (this.totalWinCash > this.rankConfig.inplayWinMaxLimitCash) {
                    this.$swal({
                        // title: '최대 당첨금액 ' + this.$root.$options.filters.comma(this.rankConfig.inplayWinMaxLimitCash) + ' $을 초과하였습니다',DO_BET_MSG_7
                        title: this.$root.$options.filters.replace(i18nConst.BET['DO_BET_MSG_7'][this.$store.state.country],'##',this.$root.$options.filters.comma(this.rankConfig.inplayWinMaxLimitCash)),
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }

                //베팅시작
                this.$swal({
                    // title: this.$root.$options.filters.comma(this.getBetCash) + '$ 베팅하시겠습니까?',
                    title: this.$root.$options.filters.replace(i18nConst.BET['DO_BET_MSG_8'][this.$store.state.country],'##',this.$root.$options.filters.comma(this.getBetCash)),
                    type: 'question',
                    confirmButtonText: i18nConst.COMMON['YES'][this.$store.state.country],
                    cancelButtonText: i18nConst.COMMON['NO'][this.$store.state.country]
                }).then((res) => {
                    this.betInfo = {selectedVals: []}
                    if (res.value) {
                        this.cartItems.forEach((item) => {
                            let txt = item.homeName + ' vs ' + item.awayName + ', ' + item.selectText
                            let val = item.fi + '-' + item.oddType + '-' + item.selectId + '-' + item.selectWay + '-' + txt
                            this.betInfo.selectedVals.push(val);
                        })
                        this.betInfo.betCash = this.getBetCash;
                        this.$store.commit(RECEIVE_SHOW_LOADING_INPLAY)
                        doinplaybet(this.betInfo).then(res => {
                            if (res.data.success) {
                                let title = i18nConst.BET['BET_SUCCESS'][this.$store.state.country];
                                this.$swal({
                                    title: title,
                                    type: 'success',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                                this.$store.commit(RECEIVE_INPLAY_CART_ITEM_DEL_ALL)
                                //베팅성공시 회$정보를 init
                                this.$store.dispatch('actionUserInfo')
                                //TODO 랭킹에서 최소베팅금액변경 변경
                                this.betCash = 0;
                                this.$bus.$emit('deleteInplayAllCartItem');
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                                this.betCash = 0
                            }
                            this.$store.commit(RECEIVE_HIDE_LOADING_INPLAY)
                        })
                    }
                })

            },

            hideCart() {
                //모바일경우 베팅카드 숨김기능
                //RightBarComp.vue에서 모니터
                this.$bus.$emit('rightMenueHandle', false)
            },
            cartLockAble() {
                this.locked = !this.locked;
                //카트고정여부
                this.$bus.$emit('cartLockAble', this.locked)
            }

        },
        computed: {
            ...mapGetters({
                'cartItems': 'getInplayCartItems',
                'rankConfig': 'getRankConfig',
                'bonusConfig': 'getBonusConfig'
            }),
            /*총배당*/
            totalOdd() {
                return this.odds.toFixed(2)
            },
            /*총당첨*/
            totalWinCash() {
                return Math.round(this.totalOdd * this.getBetCash)
            },
            /*캐쉬가 String으로 되여 전부 Integer로 전화*/
            getBetCash() {
                return parseInt(this.$root.$options.filters.replace(this.betCash, ',', ''))
            }
        },
        created() {
            this.betCash = 0
        },
        watch: {
            /*카드아이템이 변경될때마다 체크*/
            "cartItems": {
                deep: true,
                handler: function (newValue) {
                    /*총배당을 0으로 설정*/
                    this.odds = 0;
                    newValue.forEach((item) => {
                        if (this.odds === 0) {
                            this.odds = parseFloat(item.selectOdd)
                        } else {
                            this.odds = this.odds * parseFloat(item.selectOdd)
                        }
                    })
                    this.initBonus()
                }
            },
            betCash() {
                this.initBonus()
            },


        },
    }
</script>

<style scoped>


</style>