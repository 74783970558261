import { render, staticRenderFns } from "./HandicapWay3.vue?vue&type=template&id=d48ac96e&scoped=true&"
import script from "./HandicapWay3.vue?vue&type=script&lang=js&"
export * from "./HandicapWay3.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d48ac96e",
  null
  
)

export default component.exports